<template>
    <v-row no-gutters style="height:100vh" class="mx-16 justify-center">
        <v-col cols="12" class="d-flex justify-center">
         <!-- <v-img max-width="400px" style="pointer-events: none" src="/logo.svg"></v-img> -->
        </v-col>
        <v-col cols="12" class="d-flex justify-center text-h4">
          Загрузка
        </v-col>
    </v-row>

    
</template>
<script>

const LOCALIZATION = {
  RU:{
    loading_title: 'Загрузка'
  },
  EN:{
    loading_title: 'Loading'
  },
  KZ:{
    loading_title: 'Жүктеу'
  },

}

import { createSimpleExpression } from '@vue/compiler-core';

export default {
  name: 'LoadingView',
  data:()=>({
    LOCALIZATION: Object.freeze(LOCALIZATION),
    workplace_id:'',
  }),
  watch:{
    workplace_id(val){
      this.$store.dispatch('SET_ACTIVE_WORKPLACE', val)
    }
  },
  computed:{
    auth:{
      get(){
        return this.$store.getters.AUTH_STATUS
      }
      
    },
    lang(){return this.$store.getters.LANG}
    },
  created:async function(){
      await this.$store.dispatch('INIT_LANG')
      await this.$store.dispatch('INIT_HELP_MESSAGES')
      // Инициируем класс USERS
      await this.$store.dispatch('INIT_USERS')
          console.log(this.$store.getters.AUTH_STATUS )
          // if(this.$store.getters.AUTH_STATUS){
            this.$router.replace("/main")
          // }else{
          //   this.$router.replace("/auth")
          // }
          
        
      
      
  },
  computed:{
    
  },
  methods:{
    
  }
}
</script>